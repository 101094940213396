import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Footer from "../components/sections/footer"

const CompanyPage = () => (
  <Layout>
    <SEO title="About" />
    <Navigation />
    <Header />
    <p>About</p>
    <Footer />
  </Layout>
)

export default CompanyPage
