import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { Container } from "../global";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "green-skew" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  const handleSubmit = event => {
    event.preventDefault();
  };

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>
              Take control of your personal
              <br />
              finance with OPay
            </h1>
            <h2>
              The freedom and security of paying for goods, earning cash, and topping up your personal accounts is in
              your hands.
            </h2>
            <HeaderForm onSubmit={handleSubmit}></HeaderForm>
            <HeaderButton>Download app</HeaderButton>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.header`
  background-color: #f8f8f8;
  padding: 180px 0 0 0;
  position: relative;
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw)); */
`;

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;
    margin: 0 16px;

    ${({ theme }) => theme.mq.lg`
      margin: 0;
    `}
  }

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.colors.primary};
    ${props => props.theme.fontSizes.larger}
  }

  h2 {
    margin-bottom: 24px;
    ${props => props.theme.fontSizes.xl}
  }

  p {
    margin-bottom: 48px;
  }
`;

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr;
  grid-gap: 64px;

  ${({ theme }) => theme.mq.lg`
    grid-template-columns: 1fr 1fr;
    grid-gap: inherit;  
  `}
`;

const HeaderForm = styled.form`
  display: flex;

  flex-direction: column;
  padding-bottom: 16px;

  ${({ theme }) => theme.mq.md`
  flex-direction: row;
  `}
`;

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.colors.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  margin-left: 0;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
`;
const ImageWrapper = styled.div`
  justify-self: center;

  align-self: center;

  ${({ theme }) => theme.mq.lg`
  justify-self: end;
  `}
`;

const StyledImage = styled(Img)`
  width: 300px;
  display: none;

  ${({ theme }) => theme.mq.md`
  width: 400px;
  display: block;
  `}

  ${({ theme }) => theme.mq.lg`
  width: 500px;  
  `}
`;
